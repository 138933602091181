.ctaContainer {
  width: 100%;
  height: 250px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

.title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.25rem;
}

.subtitle {
  font-size: 0.75rem;
  font-weight: 300;
}

.horseButtonMakeABet {
  position: absolute;
  right: 0;
  bottom: 0;
}

.buttonBet,
.buttonRent {
  width: 310px;
  height: 101.65px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  color: white;
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  padding-left: 24px;
}

.buttonBet {
  box-shadow: 2px 9px 144px 8px rgba(87, 3, 185, 1) inset;
  -webkit-box-shadow: 2px 9px 144px 8px rgba(87, 3, 185, 1) inset;
  -moz-box-shadow: 2px 9px 144px 8px rgba(87, 3, 185, 1) inset;
  background: linear-gradient(101.61deg, rgba(47, 243, 255, 0.78) -14.72%, rgba(77, 244, 255, 0.67) 66.97%);
}

.buttonBet:hover {
  background: rgba(87, 3, 185, 1);
  box-shadow: none;
}

.buttonRent {
  box-shadow: -15px 2px 85px 19px rgb(136, 11, 235) inset;
  -webkit-box-shadow: -15px 2px 85px 19px rgb(136, 11, 235) inset;
  -moz-box-shadow: -15px 2px 85px 19px rgb(136, 11, 235) inset;
  background: linear-gradient(101.61deg, #2ff3ff -14.72%, #ff008a 66.97%);
}

.buttonRent:hover {
  background: rgb(136, 11, 235);
  box-shadow: none;
}

.horseButtonMakeARent {
  position: absolute;
  right: -38px;
  bottom: 0;
}

@media (max-width: 384px) {
  .horseButtonMakeABet {
    right: -10px;
    width: 96px;
  }
  .horseButtonMakeARent {
    width: 120px;
    right: -50px;
    top: 0;
  }
}

@media (max-width: 332px) {
  .horseButtonMakeARent {
    width: 100px;
    right: -18px;
    top: 0;
  }

  .horseButtonMakeABet {
    width: 80px;
    right: 6px;
    top: 2px;
  }
  .title {
    font-size: 1.1rem;
  }

  .subtitle {
    font-size: 0.65rem;
  }

  .ctaContainer {
    height: 240px;
    /* padding-top: 10px; */
  }

  .buttonBet,
  .buttonRent {
    width: 280px;
  }
}

@media (max-width: 300px) {
  .buttonBet,
  .buttonRent {
    width: 250px;
    padding-left: 14px;
    height: 80px;
  }

  .title {
    font-size: 1rem;
  }

  .subtitle {
    font-size: 0.5rem;
  }

  .horseButtonMakeARent {
    width: 100px;
    right: -22px;
  }

  .horseButtonMakeABet {
    width: 80px;
    right: 0px;
    top: 3px;
  }
}

@media (min-width: 768px) {
  .ctaContainer {
    width: 100%;
    height: 280px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .buttonBet,
  .buttonRent {
    width: 260px;
    font-size: 0.75rem;
  }

  .title {
    font-size: 1.25rem;
  }

  .subtitle {
    font-size: 0.75rem;
  }

  .horseButtonMakeARent {
    width: 100px;
    right: -10px;
  }

  .horseButtonMakeABet {
    width: 60px;
    right: 19px;
  }
}

@media (max-width: 768px) {
  .buttonBet,
  .buttonRent {
    width: 80%;
    margin: 0 80px;
  }
}
