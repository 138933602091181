.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left: 8px;
  height: auto;
}

.container h3 {
  font-weight: 500;
  font-size: 1.25rem;
  text-transform: uppercase;
  color: white;
}

.container p {
  font-size: 0.75rem;
  color: white;
}

@media (max-width: 320px) {
  .container {
    height: auto;
  }

  .container h3 {
    font-size: 1rem;
    padding-left: 0px;
  }

  .container p {
    padding-left: 0px;
  }
}

@media (max-width: 280px) {
  .container {
    height: auto;
  }
}

@media (max-width: 280px) {
  .container {
    height: auto;
  }

  .container h3 {
    font-size: 1rem;
  }

  .container p {
    font-size: 0.6rem;
  }
}
