.container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 50px;
}

@media (max-width: 1284px) {
  .container {
    flex-direction: column;
    height: 1200px;
    top: 280px;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .container {
    /* bottom: 100px; */
    /* top: 220px; */
  }
}

.image {
  height: 300px;
}

@media (max-width: 1284px) {
  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .image {
    padding-left: 60px;
  }
}

.h1 {
  font-size: 35px;
  font-weight: bold;
  color: #fff;
}

.spanH1 {
  font-size: 35px;
  font-weight: bold;
  color: #ffe03d;
}

.p {
  margin-top: 10px;
  width: 529px;
  height: 109px;
  line-height: 1.5;
  font-size: 20px;
  color: #fff;
}

@media (max-width: 1024px) {
  .p {
    width: 400px;
    font-size: 16px;
  }
}

.buttonRodape {
  margin-top: 2%;
  font-size: 0.625rem;
  color: #ffffff;
  margin-left: 0;
  letter-spacing: 0.26em;
  font-family: 'Roboto';
  text-align: justify;
}

.styledButton {
  width: 281px;
  height: 63px;
  box-shadow: -15px 2px 85px 19px rgba(107, 19, 216, 1) inset;
  -webkit-box-shadow: -15px 2px 85px 19px rgba(107, 19, 216, 1) inset;
  -moz-box-shadow: -15px 2px 85px 19px rgba(107, 19, 216, 1) inset;
  background: linear-gradient(101.61deg, #2ff3ff -14.72%, #ff008a 66.97%);
  border-radius: 16px;
  color: white;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  z-index: 10 !important;
}

.styledButton:hover {
  background: #b173ff;
  color: white;
}
