.container{
    padding: 48px 12px;
    background: linear-gradient(270.12deg, #3E095F 0.13%, #1E0E3F 37.45%, #310C53 81.63%, #46096A 99.93%);
    display: flex;
    flex-direction: column;
    gap: 48px;
}

@media(max-width: 769px){
    .container{
        display: flex;
        align-items: center;
    }
}