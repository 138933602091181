.container {
    background: linear-gradient(270.12deg, #3E095F 0.13%, #1E0E3F 37.45%, #310C53 81.63%, #46096A 99.93%);
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 24px 12px;
    width: 100%;
}

.titleContainer{
    width: 100%;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titleContainer h3{
    font-size: 1.125rem;
    color: #FFFFFF;
    font-weight: 700;
    text-align: center;
}

.titleContainer span{
    font-size: 1.125rem;
    color: #FFE03D;
}

.faqContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.content {
    border: 1px solid #7D00FF;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    position: relative;
    gap: 24px;
}


.description {
    display: flex;
    gap: 12px;
    align-items: center;
}

.number {
    font-size: 0.75rem;
    font-weight: bold;
}


.title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 70%;
}

.answer {
    display: none;
}

.active {
    display: block;
    /* padding: 10px 0; */
    text-align: left;
    line-height: 1.75;
    font-size: 0.75rem;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0.75; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0.75; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0.75; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0.75; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0.75; }
    100% { opacity: 1; }
  }

.buttonActive{
    position: absolute;
    right: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    outline: none;
    border: none;
    background-color: #7D00FF;
    top: 0;
    height: 40px;
    
}

.buttonDisable{
    position: absolute;
    right: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    outline: none;
    border: none;
    background-color: #B173FF;
    top: 0;
    height: 100%;
}
