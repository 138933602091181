.bannerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px;
    background-color: #AB62FF;
    color: white;
    font-weight: bold;
    gap: 8px;
}

.bannerContainer p{
    color: black;
    font-size: 0.5rem;
}

@media(max-width: 280px){
    .bannerContainer{
        gap: 4px; 
    }

    .bannerContainer p{
        font-size: 0.4rem;
    }
}