.container {
  width: 100%;
  background: linear-gradient(270.12deg, #3e095f 0.13%, #1e0e3f 37.45%, #310c53 81.63%, #46096a 99.93%);
}

.video {
  padding: 12px;
  width: 100%;
  border: none;
}

.description {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 24px;
  gap: 24px;
  color: #fff;
}

.description p {
  color: #ffffff;
  line-height: 1.5;
  font-weight: 400;
  font-size: 0.9rem;
  text-align: center;
  color: #fff;
}

.description b {
  color: #ffe03d;
}

.plataform {
  padding-left: 24px;
}

@media (max-width: 280px) {
  .description p {
    font-size: 0.75rem;
  }

  .plataform {
    padding-left: 16px;
    width: 240px;
    height: auto;
  }

  .description h3 {
    font-size: 1.125rem;
    color: #fff;
  }

  .description p {
    font-size: 0.6rem;
  }
}

@media (max-width: 768px) {
  .video {
    width: 100%;
    height: 40vh;
  }
}
