.bannerContainer {
  background-color: #ffffff;
  width: 100%;
  min-height: 420px;
  height: 480px;
  /* max-height: 480px; */
  /* padding-bottom: 20px; */
}

.image {
  position: relative;
  display: flex;
  justify-content: center;
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 64%;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  gap: 4px;
  position: absolute;
  top: 210px;
}

.semiTitle {
  font-size: 0.75rem;
  color: #9c4eff;
  font-weight: semi-bold;
  text-transform: uppercase;
  letter-spacing: 14%;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #250642;
  line-height: 1.4;
}

.title b {
  color: #9c4eff;
}

.subtitle {
  font-weight: 400;
  color: #250642;
  letter-spacing: 14%;
}

.cta {
  color: #250642;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

@media (max-width: 360px) {
  .descriptionContainer {
    top: 180px;
  }
}

@media (max-width: 320px) {
  .descriptionContainer {
    top: 178px;
  }
}

@media (max-width: 280px) {
  .bannerContainer {
    height: 400px;
  }

  .title {
    font-size: 1.2rem;
  }

  .subtitle {
    font-size: 0.75rem;
  }

  .cta {
    font-size: 0.75rem;
  }
  .descriptionContainer {
    top: 200px;
  }
}

@media (max-width: 769) {
  .bannerContainer {
    height: 400px;
  }

  .title {
    font-size: 1rem;
  }

  .subtitle {
    font-size: 0.75rem;
  }

  .cta {
    font-size: 0.75rem;
  }
}

@media (min-width: 480px) {
  .descriptionContainer {
    display: flex;
    flex-direction: column;

    padding: 28px 56px;

    position: absolute;
    top: 200px;
  }
  .image {
    height: 62%;
    object-position: top;
  }
}
