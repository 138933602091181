@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* 스크롤바 트랙 (Track) 스타일 */
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    opacity: 0.7;
  }

  /* 스크롤바 그립 (Thumb) 스타일 */
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #afafaf;
    border: 3px solid transparent;
    border-radius: 12px 12px 12px 12px;
    width: 4px;
  }

  /* 스크롤바 트랙에 대한 전체 범위 스타일 */
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #e5e5e5;
    border-radius: 12px;
    width: 6px;
  }
}

@layer base {
  .font-outline-0 {
    -webkit-text-stroke: 0px;
  }
  .font-outline-1 {
    -webkit-text-stroke: 1px black;
  }
  .font-outline-2 {
    -webkit-text-stroke: 2px black;
  }
  .font-outline-4 {
    -webkit-text-stroke: 4px black;
  }
}

html,
body {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
  overscroll-behavior-y: none;
  box-sizing: border-box;
}

/* input 기본 스타일 초기화 */
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  -webkit-text-fill-color: #000;
}

input:checked + div svg {
  @apply block;
}

@font-face {
  font-family: 'Komikax';
  src: url('./assets/fonts/komikax.ttf') format('truetype');
  /*font-weight: 900;*/
  /*font-display: swap;*/
}

@font-face {
  font-family: 'Comic';
  src: url('./assets/fonts/comic.ttf') format('truetype');
  /*font-weight: 900;*/
  /*font-display: swap;*/
}

body {
  font-family: 'Komikax', 'Comic', sans-serif;
}

/*body {*/
/*  margin: 0;*/
/*  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','NanumSquare',*/
/*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*  sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  font-size: 16px;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Pretendard', 'Courier New',*/
/*  monospace;*/
/*}*/
