.container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 48px 24px;
  background: linear-gradient(270.12deg, #3e095f 0.13%, #1e0e3f 37.45%, #310c53 81.63%, #46096a 99.93%);
}

.box {
  border-radius: 12px;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 8px;
  background: linear-gradient(180deg, #2b31da -2.1%, #7352e5 102.16%);
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.textContainer h3 {
  /* font-size: 1.2rem; */
  color: #ffd911;
  line-height: 1.7;
  font-weight: 600;
  text-align: center;
}

.textContainer p {
  /* font-size: 0.9rem; */
  padding: 0 12px;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
}

@media (max-width: 280px) {
  .textContainer h3 {
    font-size: 1rem;
  }

  .textContainer p {
    font-size: 0.6rem;
  }
}

@media (max-width: 400px) {
  .container {
    padding: 48px 8px;
  }
}
