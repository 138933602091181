/* 20220924 새로운 테이블 스타일 추가  */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Passion+One:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

/*body{background-color: #364854;}*/
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.betting {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  /* height: 100vh; */
  /* padding:20px; */
  /* background-color: #364854; */
  font-family: 'Chakra Petch', 'Montserrat', 'Passion One', 'Chakra Petch', sans-serif, cursive;
  text-transform: uppercase;
  box-sizing: content-box;
}
.betting-result {
  flex-direction: column;
  height: auto;
  /* justify-content:flex-start */
}
.trigger {
  display: flex;
  justify-content: center;
  margin: 18px 0 0;
}
.trigger li {
  font-size: 0.938rem;
  cursor: pointer;
  color: #b0b6ba;
  margin-right: 10px;
  padding: 0.375rem 0.75rem;
}
.trigger li.active {
  border-bottom: 2px solid #b0b6ba;
  background-color: transparent;
}
.tab_trigger {
  display: flex;
  justify-content: center;
  margin: 8px 0 0;
}
.tab_trigger li {
  font-size: 0.938rem;
  cursor: pointer;
  color: #b0b6ba;
  margin-right: 10px;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
}
.tab_trigger li.active {
  color: #fff;
  background-color: transparent;
}
.tab_trigger li:first-child {
  border-color: #ff6060;
  color: #ff6060;
}
.tab_trigger li:first-child:hover,
.tab_trigger li:first-child.active {
  color: #000;
  background-color: #ff6060;
  border-color: #ff6060;
}
.tab_trigger li:nth-child(2) {
  border-color: #ff9f0c;
  color: #ff9f0c;
}
.tab_trigger li:nth-child(2):hover,
.tab_trigger li:nth-child(2).active {
  color: #000;
  background-color: #ff9f0c;
  border-color: #ff9f0c;
}
.tab_trigger li:nth-child(3) {
  border-color: #3cd2a5;
  color: #3cd2a5;
}
.tab_trigger li:nth-child(3):hover,
.tab_trigger li:nth-child(3).active {
  color: #000;
  background-color: #3cd2a5;
  border-color: #3cd2a5;
}
.table_box {
  width: 100%;
  color: #345589;
  box-sizing: border-box;
}
.table_box h3 {
  margin: 2rem 0 1rem;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  /* text-shadow: 0 2px 1px #454b57; */
}
.table_box .time {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: #fff;
}
.table_box .time b {
  font-size: 1.5rem;
  padding: 0 6px 0 8px;
}
.table_box .round {
  font-size: 1.25rem;
}
.table_wrap {
  padding: 20px 20px 0 20px;
  background-color: rgba(46, 94, 164, 0.45);
  border: 1px solid #2e5ea4;
  border-radius: 6px;
  font-size: 1.5rem;
}
.list_table {
}
.list-header {
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  border-radius: 3px;
  overflow: hidden;
}
.list-header > div,
.list-body-tr > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-header-th {
  font-weight: bold;
  font-size: 1.2rem;
}
.list-body-tr {
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  border-radius: 3px;
  background: linear-gradient(#fdfdfd, #a2a2a4);
  box-shadow: 0 4px 0 #043373, 1px 0 0 #5a77a4;
  overflow: hidden;
}
.list-header > div {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: linear-gradient(#d7e7fe, #b2c8f8);
}
.list-body-th {
  background: linear-gradient(#d7e7fe, #b2c8f8);
  border: 0px solid #4e73ad;
  border-width: 0px 0px 1px 2px;
  clip-path: polygon(0 0, 100% 0, 92% 100%, 0% 100%);
  z-index: 200;
}
.list-body-th:first-child {
  border-radius: 3px 0 0 3px;
}
.list-body-td {
  padding: 12px 16px;
  background: linear-gradient(#fdfdfd, #a2a2a4);
}
.list-body-td:last-child {
  border-radius: 0 3px 3px 0;
}
.table_box .cols2 > * {
  width: 50%;
}
.table_box .cols3 > * {
  width: 33.333%;
}
.table_box .cols4 > * {
  width: 25%;
}

.table_box_ranking .list-header-th,
.table_box_ranking .list-body-th {
  width: 15%;
}
.table_box_ranking .list-header-td {
  width: 85%;
}
.table_box_ranking .list-body-td {
  width: 85%;
  padding-right: 18%;
}

.color-red {
  color: #ec6964;
}
.color-red-sec {
  color: #c03479;
}
.color-yellow {
  color: #f0a43c;
}
.color-yellow-sec {
  color: #e6d965;
}
.color-green {
  color: #98c73d;
}
.color-green-sec {
  color: #8ec21f;
}
.color-sky {
  /* color:#638DDA */
  color: #739dea;
}
.color-sky-sec {
  color: #67a2e6;
}
.color-blue {
  /* color:#2B5CAB */
  color: #5b9cfb;
}
.color-blue-sec {
  color: #0a5ce6;
}
.color-navy {
  color: #3e7b97;
}
.color-navy-sec {
  color: #386f88;
}
.color-purple {
  /* color:#823AD5 */
  color: #c46af5;
}
.color-purple-sec {
  color: #8c33e2;
}
.color-deeppurple {
  color: #3d3c63;
}
.color-deeppurple-sec {
  color: #313150;
}
.color-black {
  color: #404041;
}
.color-gray {
  color: #d3d2d2;
}
.color-white {
  color: #fff;
}

.bg-red {
  background-color: #ec6964;
}
.bg-red-sec {
  background-color: #c03479;
}
.bg-yellow {
  background-color: #f0a43c;
}
.bg-yellow-sec {
  background-color: #e6d965;
}
.bg-green {
  background-color: #98c73d;
}
.bg-green-sec {
  background-color: #8ec21f;
}
.bg-sky {
  background-color: #638dda;
}
.bg-sky-sec {
  background-color: #207ce6;
}
.bg-blue {
  background-color: #2b5cab;
}
.bg-blue-sec {
  background-color: #004bc9;
}
.bg-navy {
  background-color: #3e7b97;
}
.bg-navy-sec {
  background-color: #386f88;
}
.bg-purple {
  background-color: #823ad5;
}
.bg-purple-sec {
  background-color: #791dd4;
}
.bg-deeppurple {
  background-color: #3d3c63;
}
.bg-deeppurple-sec {
  background-color: #313150;
}
.bg-black {
  background-color: #404041;
}
.bg-gray {
  background-color: #d3d2d2;
}
.bg-white {
  background-color: #fff;
}

.tb-header {
  width: 100%;
  display: flex;
}
.tb-header > div {
}
.tb-header > div,
.tb-body-tr > div {
  display: flex;
  align-items: center;
  justify-content: center;
  /* word-break: break-word; */
}
.tb-header-th {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 12px 16px;
  color: #111520;
  font-weight: 600;
  font-size: 1rem;
  /* font-style: italic; */
  text-shadow: 1px 1px #45454599;
  box-sizing: border-box;
}
.tb-head {
  width: 100%;
}
.tb-head-tr {
  display: flex;
  width: 100%;
}
.tb-head-tr .tb-header-th {
  justify-content: center;
}
.tb-body {
  display: flex;
  width: 100%;
  /* overflow: auto; */
}
.tb-body-tr .td-select-box {
  color: #f0a43c;
}
.tb-span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ooeTb .tb-span {
  border: 1px double #ed4096;
}
.ooeTb .tb-span .tb-header-th {
  color: #ed4096 !important;
}
.tb-body-tr > li {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  min-height: 34px;
  padding: 8px 16px;
  /* text-shadow:  1px 1px #45454599; */
  border: 1px solid;
  margin: 1px;
  box-shadow: inset 0px 1px 2px rgb(22 26 28);
  box-sizing: content-box;
}
/* .tb .tb-body-tr > li:nth-child(even){
    background-color:#12191e75;
}
.table-odd .tb-body-tr > li:nth-child(odd){
    background-color:#12191e75;
} */
/* .tb-body-tr:nth-child(1){
    z-index: 4;
}
.tb-body-tr:nth-child(2){
    z-index: 3;
}
.tb-body-tr:nth-child(3){
    z-index: 2;
}
.tb-body-tr:nth-child(4),
.tb-body-tr:last-child{
    z-index: 1;
    box-shadow: none;
} */
/* .tb .tb-body-tr.bg-red > li:nth-child(even){
    background-color:#C03479;
}
.tb .tb-body-tr.bg-yellow > li:nth-child(even){
    background-color:#E09B59;
}
.tb .tb-body-tr.bg-sky > li:nth-child(even){
    background-color:#5498CA;
}
.tb .tb-body-tr.bg-blue > li:nth-child(even){
    background-color:#5C8BB4;
}
.tb .tb-body-tr.bg-navy > li:nth-child(even){
    background-color:#386F88;
}
.tb .tb-body-tr.bg-purple > li:nth-child(even){
    background-color:#65579A;
}
.tb .tb-body-tr.bg-deeppurple > li:nth-child(even){
    background-color:#313150;
}
.table-odd .tb-body-tr.bg-red > li:nth-child(odd){
    background-color:#C03479;
}
.table-odd .tb-body-tr.bg-yellow > li:nth-child(odd){
    background-color:#E09B59;
}
.table-odd .tb-body-tr.bg-sky > li:nth-child(odd){
    background-color:#5498CA;
}
.table-odd .tb-body-tr.bg-blue > li:nth-child(odd){
    background-color:#5C8BB4;
}
.table-odd .tb-body-tr.bg-navy > li:nth-child(odd){
    background-color:#386F88;
}
.table-odd .tb-body-tr.bg-purple > li:nth-child(odd){
    background-color:#65579A;
}
.table-odd .tb-body-tr.bg-deeppurple > li:nth-child(odd){
    background-color:#313150;
} */
.tb-body-td {
  font-size: 1rem;
  font-weight: 700;
}
.total {
  margin: 32px auto;
  font-size: 1.5rem;
  color: #fff;
}

.table-select {
  background: none;
  border: 0 none;
  /* font-size: 16px; */
  font-weight: 800;
  font-style: italic;
  text-shadow: 1px 1px #3c3c3c61;
}
.table-select option {
  background-color: #fff;
}
.table-select option:nth-child(even) {
  background-color: #d3d2d2;
}

/* popup */

.modal {
  background-color: #1e2831;
  box-shadow: 0px 2px 10px #6d6c6c;
  color: #fff;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  padding: 10px 20px;
  min-width: 300px;
}
.modal:focus-visible {
  border: 0 none;
  outline: none;
}
.modal::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: linear-gradient(to right, #ed4096 33.3%, #fff170 33.3% 66.6%, #248aff 66.6% 99.9%);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 900;
}
.modal > .contents p {
  font-size: 1.125rem;
  font-weight: 700;
}
.modal > .onlyText p {
  margin-top: 0;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
.modal .btn-close {
  display: block;
  margin-left: auto;
  background: transparent;
  border: 0 none;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.modal .btn-close:after {
  content: '\00d7';
  position: absolute;
  right: 8px;
  top: 0;
  display: inline-block;
  font-size: 30px;
  color: #fff;
}
.modal > .actions {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 10px 0;
  margin: auto;
  text-align: center;
}
.modal > .actions button {
  min-width: 30%;
  padding: 6px 20px;
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}
.ui.dropdown > .dropdown.icon:before {
  content: '\f0d7';
}
/* 
.table-select > div{
    height: auto;
    background-color: transparent;
    border: 0 none;
    color: #fff;
} */

/** Carousel **/

.carousel .carouselArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.carousel .carouselArrow button {
  background: transparent;
  border: 1px solid #b0b5bc;
  width: 26px;
  height: 26px;
  border-radius: 3px;
}
.carousel .carouselArrow button:hover {
  background-color: rgba(255, 255, 255, 0.09);
}
.carousel .roundText {
  color: #fff;
  padding: 0 20px;
}
.arrow-prev,
.arrow-next {
  position: relative;
  /* float:left; */
  border: 1px solid #b0b5bc;
  margin-right: 5px;
}
.arrow-prev:after {
  position: absolute;
  left: 10px;
  top: 7px;
  content: '';
  width: 8px; /* 사이즈 */
  height: 8px; /* 사이즈 */
  border-top: 2px solid #b0b5bc; /* 선 두께 */
  border-right: 2px solid #b0b5bc; /* 선 두께 */
  transform: rotate(225deg); /* 각도 */
}

.arrow-next:after {
  position: absolute;
  right: 10px;
  top: 7px;
  content: '';
  width: 8px; /* 사이즈 */
  height: 8px; /* 사이즈 */
  border-top: 2px solid #b0b5bc; /* 선 두께 */
  border-right: 2px solid #b0b5bc; /* 선 두께 */
  transform: rotate(45deg); /* 각도 */
}

.ooeTb .tb-body-td {
  font-weight: 800;
}

.vsFigWrap {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100px;
  background-image: url('../img/vsImageTop.svg');
  /* 이미지추가 및 이미지경로 수정해서 올려주세요 */
  background-repeat: no-repeat;
  background-position: center top;
}
.vsFigWrap::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 38px;
  border-radius: 50px;
  background: linear-gradient(
    180deg,
    #8f8f8f -21.4%,
    #989898 -19.4%,
    #b4b4b4 -11.92%,
    #cbcbcb -4.07%,
    #dadada 4.66%,
    #e4e4e4 14.51%,
    #e7e7e7 28.11%,
    #737373 102.8%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: -10;
}
.vsFigWrap::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 38px;
  border-radius: 50px;
  background: linear-gradient(
    180deg,
    #8f8f8f -21.4%,
    #989898 -19.4%,
    #b4b4b4 -11.92%,
    #cbcbcb -4.07%,
    #dadada 4.66%,
    #e4e4e4 14.51%,
    #e7e7e7 28.11%,
    #737373 102.8%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: -10;
}
.oddsText {
  position: absolute;
  left: 48%;
  top: 35px;
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
}
.vsFigWrap .barWrap {
  display: block;
  height: 38px;
  width: 100%;
  position: absolute;
  top: 3px;
}
.vsFigWrap .bar {
  width: 100%;
  height: 31px;
}
.oddTextStyle {
  position: relative;
  width: 50%;
  height: 38px;
}
.oddTextStyle span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #004bac;
  font-weight: bold;
  padding-top: 8px;
  padding-left: 8px;
  text-shadow: 0px 1px 1px #192e57;
  box-sizing: border-box;
  z-index: -1;
}
.oddTextStyle .barWrap {
  display: flex;
  justify-content: flex-end;
  left: 3px;
  /*margin-right: 70px;*/
}
.oddTextStyle .bar {
  border-radius: 32px 0 0 32px;
  background: linear-gradient(
    180deg,
    #25437b 1.9%,
    #214584 4.71%,
    #1351ab 17.94%,
    #085ac8 30.17%,
    #0260d9 40.68%,
    #0062e0 48.47%,
    #0160db 57.26%,
    #055dcf 66.04%,
    #0d56bb 74.83%,
    #174e9f 83.62%,
    #24437b 92.32%,
    #25437b 92.5%
  );
}
.evenTextStyle {
  position: relative;
  width: 50%;
  height: 38px;
}
.evenTextStyle span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #e24343;
  font-weight: bold;
  padding-top: 8px;
  padding-right: 8px;
  text-shadow: 0px 1px 1px #8d3536;
  box-sizing: border-box;
  z-index: -1;
}
.evenTextStyle .barWrap {
  display: flex;
  justify-content: flex-start;
  right: 3px;
  /*margin-left: 70px;*/
}
.evenTextStyle .bar {
  border-radius: 0 32px 32px 0;
  background: linear-gradient(
    180deg,
    #a84042 -4.8%,
    #b94647 1.49%,
    #d75152 14.69%,
    #ed5959 27.78%,
    #fa5e5e 40.25%,
    #ff6060 51.6%,
    #f95e5e 61.18%,
    #ea5858 73.04%,
    #d14f50 86.13%,
    #b34446 98.3%
  );
}
.persentText {
  position: absolute;
  top: 10px;
  color: #000;
}
.oddTextStyle .persentText {
  right: 8px;
}
.evenTextStyle .persentText {
  left: 8px;
}
.vsCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 38px;
  background-color: #000;
  color: #fff;
}

.buttonStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  background-color: transparent;
  padding: 2px 4px;
  border-radius: 3px;
  min-width: 34px;
  min-height: 32px;
  margin: 0 4px;
  box-sizing: border-box;
}

.border-green {
  border-color: #98c73d;
}

.historyReplay .tb-body-tr > li {
  min-height: 44px;
}
.historyReplay .buttonStyle svg {
  width: 18px;
}
.historyReplay .tb-body-tr {
  width: 16.6%;
}
.current-sell {
  border-color: #192024 !important;
  background-color: #00000052 !important;
}

/* media query */

@media all and (max-width: 780px) {
  .tb-header-th {
    font-size: 0.938rem;
    height: 60px;
  }
  .tb-body-td,
  .tb-body-tr > li {
    font-size: 0.875rem;
    padding: 2px 4px;
  }
}
@media all and (max-width: 504px) {
  .betting {
    /* padding: 8px; */
  }
  .table_box h3 {
    font-size: 2rem;
  }
  .table_box .time,
  .table_box .round,
  .total {
    font-size: 1rem;
  }
  .table_box .time b {
    font-size: 1.125rem;
  }
  .tb-body-td.td-select-box {
    padding: 2px 2px;
  }
  .tb-header-th {
    font-size: 0.813rem;
    padding: 0;
  }
  .tb-body-td,
  .tb-body-tr > li {
    font-size: 0.813rem;
  }
  .historyReplay .tb-body-tr > li {
    min-height: 60px;
    display: flex;
    flex-direction: column;
  }
  .historyReplay .buttonStyle {
    min-width: 26px;
    min-height: 26px;
    margin: 2px 2px;
  }
  .historyReplay .buttonStyle svg {
    width: 12px;
  }
}
@media all and (max-width: 415px) {
  .table_box h3 {
    font-size: 1.5rem;
  }
  .table_box .time,
  .total {
    /* font-size: 0.813rem; */
  }
  .tb-header-th {
    /* font-size: 0.8rem; */
  }
  .tb-body-td,
  .tb-body-tr > li {
    font-size: 0.815rem;
    word-break: break-all;
  }
  .modal {
    width: 80vw;
    min-width: 280px;
  }
  .modal > .contents p {
    font-size: 0.875rem;
  }
  .modal > .actions button {
    font-size: 0.813rem;
  }
}
@media all and (max-width: 406px) {
  .vsFigWrap {
    font-size: 0.875rem;
    background-size: 80% 80%;
  }
  .oddsText {
    top: 43px;
    font-size: 1rem;
  }
  .vsCenter {
    width: 60px !important;
  }
}
