.header {
  width: 100%;
  /* height: 20px; */
  background-color: white;
  border-bottom: 1px solid #e2e3c6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
}

.button {
  color: #250642;
  border-radius: 20px;
  font-size: 0.6rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.button:hover {
  color: #b173ff;
}

.buttonContainer {
  display: flex;
  gap: 36px;
}

@media (min-width: 290px) {
  .header {
    padding: 0 1rem;
  }

  .buttonContainer {
    gap: 12px;
  }
}

@media (max-width: 769px) {
  .header {
    padding: 0 1rem;
  }

  .buttonContainer {
    gap: 12px;
  }
}
