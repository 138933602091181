.container {
  /* width: 100vw; */
  /* height: 100%; */
  /* overflow: hidden; */
  /* margin-top: -20%;
  margin-left: -5%; */
  display: flex;
  justify-content: center;
}

.backgroundImage {
  position: relative;
}

.videoWrapper {
  position: relative;
  transform: translate(-50%, -50%);
  height: 1000px;
  /* padding-bottom: 39.25%; */
  overflow: hidden;
  width: 500px;
  top: 200px;
  /* right: 20%; */
  /* left: calc(50% - 500px); */
  padding-top: 100px;
}

.videoWrapper {
  @media (max-width: 1024px) {
    /* top: 26%;
    width: 41%;
    height: 1000px;
    right: 10%; */
  }
}

.video {
  position: absolute;
  top: 200px;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
}

.video {
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    top: 100px;
  }
}
