.container {
  width: 100%;
  padding: 0 12px;
  display: flex;
  justify-content: center;
}

.buttonRent{
  width: 100%;
  height: 101.65px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  color: white;
  align-items: center;
  padding-left: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: -15px 2px 85px 19px rgb(136,11,235) inset;
  -webkit-box-shadow: -15px 2px 85px 19px rgb(136,11,235) inset;
  -moz-box-shadow: -15px 2px 85px 19px rgb(136,11,235) inset;
  background: linear-gradient(101.61deg, #2FF3FF -14.72%, #FF008A 66.97%);
}

.arrow{
  width: 60px;
  height: 60px;
}

.description h3{
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
}

.description p{
  font-size: 0.75rem;
  font-weight: 300;
}

@media(max-width: 280px){
  .buttonRent {
    width: 100%;
    height: 70px;
  }

  .description h3{
    font-size: 0.75rem;
  }

  .description p{
    font-size: 0.6rem;
  }

  .arrow{
    width: 40px;
    height: 40px;
  }
}