.container {
  width: 100%;
  padding: 0 12px;
  display: flex;
  justify-content: center;
}

.buttonBet {
  width: 100%;
  height: 101.65px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  color: white;
  padding-left: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: 2px 9px 144px 8px rgba(87, 3, 185, 1) inset;
  -webkit-box-shadow: 2px 9px 144px 8px rgba(87, 3, 185, 1) inset;
  -moz-box-shadow: 2px 9px 144px 8px rgba(87, 3, 185, 1) inset;
  background: linear-gradient(101.61deg, rgba(47, 243, 255, 0.78) -14.72%, rgba(77, 244, 255, 0.67) 66.97%);
}

.arrow {
  width: 60px;
  height: 60px;
}

.description h3 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  text-align: start;
}

.description p {
  font-size: 0.75rem;
  font-weight: 300;
  text-align: start;
}

@media (max-width: 360px) {
  .arrow {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 280px) {
  .buttonBet {
    width: 100%;
    height: 70px;
  }

  .description h3 {
    font-size: 0.75rem;
  }

  .description p {
    font-size: 0.6rem;
  }

  .arrow {
    width: 40px;
    height: 40px;
  }
}
