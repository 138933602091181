.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
}

.social {
  display: flex;
  gap: 12px;
}

.socialLinks {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 48px 0;
}

.icons {
  border-radius: 24px;
  background-color: #7d00ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.icons:hover {
  background-color: #b173ff;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding-bottom: 12px;
}

.loginBtn {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 127px;
  height: 42px;
  font-weight: 700;
  cursor: pointer;
}

.loginBtn:hover {
  background-color: #b173ff;
}

.registerBtn {
  background-color: white;
  color: #290b4d;
  border: 1px solid black;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 157px;
  height: 42px;
  font-weight: 700;
  cursor: pointer;
}

.registerBtn:hover {
  background-color: #b173ff;
  color: white;
}

.links {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.linkGroup {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.linkGroup h4 {
  text-align: center;
  font-weight: bold;
  color: white;
}

.linkGroup a {
  text-align: center;
  font-size: 0.9rem;
  color: white;
  text-decoration: none;
  word-break: break-word;
  width: 160px;
  color: white;
}

.terms {
  display: flex;
  gap: 12px;
  padding: 12px;
  color: white;
}

.terms a {
  font-size: 0.6rem;
  letter-spacing: 0.1rem;
}

.copyright {
  padding: 12px 0;
  border-top: 1px solid #573886;
  font-size: 0.5rem;
  letter-spacing: 0.1rem;
  text-align: center;
  color: white;
}

@media (max-width: 333px) {
  .loginBtn {
    width: 100px;
  }
  .registerBtn {
    width: 140px;
  }
}

@media (max-width: 280px) {
  .loginBtn {
    width: 90px;
    font-size: 0.6rem;
  }
  .registerBtn {
    width: 120px;
    font-size: 0.6rem;
  }

  .copyright {
    font-size: 0.3rem;
  }

  .linkGroup a {
    font-size: 0.6rem;
  }

  .terms a {
    font-size: 0.5rem;
  }

  .icons {
    width: 40px;
    height: 40px;
    border-radius: 18px;
  }
}
