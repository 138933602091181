.bannerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #ab62ff;
  color: white;
  font-weight: bold;
}

.icon {
  margin: 0 10px;
}

.p {
  color: black;
}
