.lottie1 {
    /* font-family: arial;
    font-size: 24px;
    margin: 25px;
    width: 350px;
    height: 200px;
    outline: dashed 1px black;
    
    display: flex;
    justify-content: center;
    left: 50%;
    position: absolute; */
    position: absolute;
    display: block;
    width: 640px;
    height: 50%;
    /* border: 1px solid black; */
    /* border-radius: 50%; */
    overflow: hidden;
    /* background-position: top calc(50% + 5px) left 50%;         */
    /* background-position: left 50%;         */
    transform: translate(-1/2,0);
  }