.container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px;
  margin-bottom: 48px;
  background: linear-gradient(270.12deg, #3e095f 0.13%, #1e0e3f 37.45%, #310c53 81.63%, #46096a 99.93%);
}

.box {
  border-radius: 12px;
  width: 320px;
  height: auto;
  box-shadow: 0px 1px 400px 45px rgba(26, 2, 52, 0.7) inset;
  -webkit-box-shadow: 0px 1px 400px 45px rgba(26, 2, 52, 0.7) inset;
  -moz-box-shadow: 0px 1px 400px 45px rgba(26, 2, 52, 0.7) inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #7622d3;
}

.textContainer {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.textContainer h3 {
  font-size: 1.125rem;
  color: #ffd911;
  line-height: 1.5;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}

.textContainer p {
  font-size: 0.75rem;
  padding: 0 12px;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
}

@media (max-width: 280px) {
  .textContainer h3 {
    font-size: 1rem;
  }

  .textContainer p {
    font-size: 0.6rem;
  }
}
