.pageContainer {
  background: linear-gradient(270.12deg, #3e095f 0.13%, #1e0e3f 37.45%, #310c53 81.63%, #46096a 99.93%);
  font-family: 'Arial', sans-serif;
  color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 48px 0;
}

.videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.logoSection {
  text-align: center;
  margin-top: 1rem;
}

.promoSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.registerButton {
  background-color: #5e52aa;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 1rem;
  cursor: pointer;
}

.faqs {
  width: 65%;
  /* align-items:center; */
  /* justify-content: center; */
  /* padding: 20px 80px; */
  /* margin-right: 15%; */
  /* margin-left: 17%; */
  /* align-items: center; */
}

.faqItem {
  border: 1.2px solid #7d00ff;
  padding: 10px 0;
  /* width: 1300px; */
  display: flex;
  flex-direction: column; /* adicione esta linha */
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 20px 32px;
  position: relative;
}

.faqContainer {
  /* background-color: #3F357A; */
  height: 60px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.faqQuestionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.faqQuestion {
  cursor: pointer;
}

.faqAnswer {
  display: none; /* inicialmente escondido */
}

/* ... estilos existentes ... */

.faqNumber {
  font-size: 24px;
  font-weight: bold;
  margin-right: 20px;
}

.faqQuestionTitle {
  flex: 1;
  font-size: 21px;
  font-weight: 520;
  text-align: justify;
}

.faqIcon {
  position: absolute;
  width: 70px;
  height: 100%; /* Faz o ícone preencher toda a altura do container */
  background-color: #a991e6;
  backdrop-filter: blur(7.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; /* Define o tamanho da cruz e do sinal de "-" */
  font-weight: bold;
  margin-left: 94.8%;
  border-radius: 0 6px 6px 0;
  top: 0;
  right: -68px;
}

.faqIcon::before,
.faqIcon::after {
  content: '';
  position: absolute;
  /* background-color: white; */
  transition: transform 0.3s ease;
}
.faqIcon::before {
  content: '+';
  position: relative;
  font-size: 24px;
  transition: transform 0.3s ease;
  width: 2px;
  height: 30px; /* Adjust height to match the height of the square */
  left: -8%;
}
/* 
.faqIcon::after {
    width: 30px; Adjust width to match the width of the square
    height: 2px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
} */

.faqActive .faqIcon::before {
  content: '-';
  transform: scaleY(1);
  left: -5%;
}

.faqActive .faqIcon::after {
  transform: translateX(-50%) scaleY(0);
}
.faqAnswer {
  display: none;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  font-size: 17px;
}

.faqActive .faqIcon {
  background-color: #7d00ff;
}

.faqActive .faqAnswer {
  display: block;
}

.pPerguntas {
  margin-top: 0;
  font-size: 28px;
  font-weight: 600;
}

.spanDuvidas {
  margin-top: -10%;
  font-size: 28px;
  font-weight: 600;
  color: #ffe03d;
}

.imagens {
  margin-top: -20%;
}
