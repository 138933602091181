.container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px;
  background: linear-gradient(270.12deg, #3e095f 0.13%, #1e0e3f 37.45%, #310c53 81.63%, #46096a 99.93%);
}

.box {
  border-radius: 12px;
  width: 320px;
  height: 360px;
  box-shadow: 0px 1px 94px 45px rgba(34, 30, 255, 0.7) inset;
  -webkit-box-shadow: 0px 1px 94px 45px rgba(34, 30, 255, 0.7) inset;
  -moz-box-shadow: 0px 1px 94px 45px rgba(34, 30, 255, 0.7) inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(53, 0, 165, 0.81);
}

.textContainer {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.textContainer h3 {
  font-size: 1.125rem;
  color: #ffffff;
  padding: 0 12px;
  line-height: 1.5;
  font-weight: 500;
  /* text-transform: uppercase; */
  text-align: center;
}

.textContainer b {
  color: #ffd911;
}

.textContainer p {
  font-size: 0.5rem;
  padding: 0 12px;
  line-height: 1.5;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
}

.cta {
  width: 281px;
  height: 63px;
  box-shadow: -15px 2px 70px 19px rgba(107, 19, 216, 1) inset;
  -webkit-box-shadow: -15px 2px 70px 19px rgba(107, 19, 216, 1) inset;
  -moz-box-shadow: -15px 2px 70px 19px rgba(107, 19, 216, 1) inset;
  background: linear-gradient(101.61deg, #2ff3ff -14.72%, #ff008a 66.97%);
  border-radius: 16px;
  color: white;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

.cta:hover {
  background: #b173ff;
  color: white;
}

@media (max-width: 330px) {
  .box {
    border-radius: 12px;
    width: 280px;
  }
  .cta {
    width: 250px;
  }
}
@media (max-width: 280px) {
  .box {
    width: 260px;
  }

  .cta {
    width: 240px;
  }

  .textContainer h3 {
    font-size: 1rem;
  }
}
