.container {
  background: url('@/img/background.png') no-repeat center center;
  background-size: contain;
  font-family: 'Arial', sans-serif;
  width: 100%;
  height: 100%;
  color: white;
  position: relative;
  background-color: #ffff;
  padding-top: 50px;
}

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.logo {
  font-size: 2em;
}

.buttons {
  display: flex;
  gap: 10px;
  /* margin-left: 80%; */
}

.loginBtn,
.registerBtn,
.betBtn,
.rentBtn {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.loginBtn {
  background-color: transparent;
  border: 2px solid #9c4eff;
  color: #9c4eff;
  border-radius: 20px;
  width: 157px;
  height: 42px;
  font-weight: 700;
}

.registerBtn {
  background-color: #9c4eff;
  color: white;
  border: 1px solid black;
  border-radius: 20px;
  width: 157px;
  height: 42px;
  font-weight: 700;
}

.main {
  /* text-align: left; */
  /* margin-top: 100px;   */
}

.title {
  font-size: 2em;
  color: #9c4eff;
  font-size: 13.04px;
  font-weight: semi-bold;
  letter-spacing: 0.2em;
}

.subtitle {
  font-size: 44px;
  font-weight: bold;
  margin: 20px 0;
  color: black;
}

.bonus {
  color: #9c4eff;
}

.description {
  margin-bottom: 20px;
  color: #250642;
  font-size: 21.52px;
  width: 90%;
}

.betBtn {
  background-color: blue;
  color: white;
  margin-right: 20px;
}

.rentBtn {
  background-color: purple;
  color: white;
}

.image {
  margin-left: 200px;
  margin-top: 43px;
}

.divFlex {
  background: linear-gradient(270.12deg, #3e095f 0.13%, #1e0e3f 37.45%, #310c53 81.63%, #46096a 99.93%);
  display: flex;
  justify-content: space-evenly;
  margin: auto;
}

.banner {
  background: linear-gradient(270.12deg, #3e095f 0.13%, #1e0e3f 37.45%, #310c53 81.63%, #46096a 99.93%);
  display: flex;
  justify-content: center;
}

.imgFaq {
  margin-right: 80%;
}

.conteinerText {
  width: 580px;
  height: 300px;
  margin-left: 17%;
  margin-top: -1%;
}

.alignButtons {
  display: flex;
  justify-content: flex-start;
  margin-left: 17%;
  margin-top: 50px;
  gap: 96px;
}

.buttonApostar {
  box-shadow: 2px 9px 94px 8px rgba(87, 3, 185, 1) inset;
  -webkit-box-shadow: 2px 9px 94px 8px rgba(87, 3, 185, 1) inset;
  -moz-box-shadow: 2px 9px 94px 8px rgba(87, 3, 185, 1) inset;
  background: linear-gradient(101.61deg, rgba(47, 243, 255, 0.78) -14.72%, rgba(77, 244, 255, 0.67) 66.97%);
  width: 500px;
  height: 101.65px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  color: white;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  gap: 8px;
  position: relative;
  padding-left: 24px;
}

.buttonAlugar {
  box-shadow: -15px 2px 85px 19px rgb(136, 11, 235) inset;
  -webkit-box-shadow: -15px 2px 85px 19px rgb(136, 11, 235) inset;
  -moz-box-shadow: -15px 2px 85px 19px rgb(136, 11, 235) inset;
  background: linear-gradient(101.61deg, #2ff3ff -14.72%, #ff008a 66.97%);
  width: 500px;
  height: 101.65px;
  border-radius: 16px;
  color: white;
  gap: 8px;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  gap: 8px;
  position: relative;
  padding-left: 24px;
}

.textButtonBet {
}

.arrow {
}

.horseButtonMakeABet {
  position: absolute;
  right: 0;
  bottom: 0;
}

.horseButtonRantAHorse {
  position: absolute;
  right: -60px;
  bottom: 0;
}

.pMakeABetTitle {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 28px;
}

.pMakeABetSubTitle {
  font-size: 1rem;
  font-weight: 300;
}
