body {
  padding: 0;
  margin: 0;
}

#unity-container {
  position: absolute;
}
#unity-container.unity-desktop {
  width: 100%;
  height: 100%;
  left: 30;
  top: 30;
  border: 0;
  padding: 0;
}
#unity-container.unity-mobile {
  width: 100%;
  height: 100%;
}
#unity-canvas {
  background: #231f20;
}

.unity-mobile #unity-canvas {
  width: 100%;
  height: 100%;
}
#unity-logo {
  width: 154px;
  height: 130px;
  background: url('../img/unity/unity-logo-dark.png') no-repeat center;
}
#unity-footer {
  position: relative;
}
.unity-mobile #unity-footer {
  display: none;
}
#unity-webgl-logo {
  float: left;
  width: 204px;
  height: 38px;
  background: url('../img/unity/webgl-logo.png') no-repeat center;
}
#unity-build-title {
  float: right;
  margin-right: 10px;
  line-height: 38px;
  font-family: arial;
  font-size: 18px;
}
#unity-fullscreen-button {
  float: right;
  width: 38px;
  height: 38px;
  background: url('../img/unity/fullscreen-button.png') no-repeat center;
}
#unity-warning {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%);
  background: white;
  padding: 10px;
  display: none;
}

#unity-loading-bar {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
#unity-Web_loading_1 {
  width: 300px;
  height: 225px;
  scale: 0.7;
  object-fit: none;
  background: url('../img/unity/Web_loading_1.gif') no-repeat center;
}
#unity-Web_loading_2 {
  width: 300px;
  height: 225px;
  scale: 0.7;
  background: url('../img/unity/Web_loading_2.gif') no-repeat center;
}
#unity-progress-bar-empty {
  width: 141px;
  height: 18px;
  scale: 0.7;
  margin-top: 0px;
  margin-left: 29.5px;
  background: url('../img/unity/progress-bar-empty-dark.png') no-repeat center;
}
#unity-progress-bar-full {
  width: 0%;
  height: 18px;
  margin-top: 150px;
  background: url('../img/unity/progress-bar-full-dark.png') no-repeat center;
}
