.boxDescription {
  display: flex;
  margin-left: 8px;
}

.line {
  height: 410px;
  width: 2px;
  background-color: rgba(163, 106, 255, 1);
  position: absolute;
  z-index: 1;
  opacity: 0.3;
  left: 23px;
}
@media screen and (min-width: 768px) {
  .line {
    height: 410px;
    width: 2px;
    background-color: rgba(163, 106, 255, 1);
    position: absolute;
    z-index: 1;
    opacity: 0.3;
    left: 220px;
  }
}
.boxContainer {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 24px;
  position: relative;
}

.box {
  width: 320px;
  height: auto;
  background-color: rgba(118, 34, 211, 1);
  border-radius: 24px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  z-index: 1;
}

.box h6 {
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
}

.box strong {
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
}

.box p {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.75;
}

.box a {
  margin-left: 4px;
  color: rgba(102, 246, 255, 1);
  font-size: 0.75rem;
  text-decoration: none;
}

.indicator {
  width: 40px;
  height: 40px;
  border: 2px solid rgba(169, 102, 255, 1);
  background-color: rgba(26, 2, 52, 0.74);
  color: rgba(169, 102, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 0.75rem;
  position: absolute;
  top: 0;
  left: -20px;
  z-index: 3;
}
@media (max-width: 768px) {
  .line {
    display: none;
  }
}

/* @media (max-width: 414px) {
  .box {
    width: 360px;
  }
} */

/* @media (max-width: 395px) {
  .box {
    width: 345px;
  }
} */

@media (max-width: 375px) {
  .box {
    width: 300px;
  }
}

@media (max-width: 360px) {
  .line {
    height: 400px;
  }
  .box {
    width: 260px;
    height: auto;
  }
}

@media (max-width: 320px) {
  .indicator {
    width: 36px;
    height: 36px;
    top: -20px;
    left: -10px;
    z-index: 3;
  }
}

@media (max-width: 280px) {
  .line {
    height: 360px;
  }

  .container {
    height: 780px;
  }

  .box {
    width: 240px;
    height: auto;
    padding: 16px 18px;
  }

  .box p {
    line-height: 1.5;
    font-size: 0.6rem;
  }

  .box a {
    font-size: 0.6rem;
  }

  .box h6,
  .box strong {
    font-size: 1rem;
  }
}
