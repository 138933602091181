.container {
  overflow-x: hidden;
  background: linear-gradient(270.12deg, #3e095f 0.13%, #1e0e3f 37.45%, #310c53 81.63%, #46096a 99.93%);
  width: 100%;
}

@media (min-width: 769px) {
  .container {
    display: none;
  }
}
